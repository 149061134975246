
main {
	margin: 0 auto;
	max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #8c54ff;
  --secondary: #F3A847;
  --tertiary: #B12704;
  --light: #fafafa;
  --dark: #000000;
  --darker: #111111;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);

}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 600;
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: .1em;
  background: #000000;
  background: var(--dark);
  color: #fafafa;
  color: var(--light);
  font-family: 'Poiret One', cursive;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: #B12704;
  color: var(--tertiary);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

header {
  background: #000000;
  background: var(--dark);
}

header a {
  color: #fafafa;
  color: var(--light);
}

button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: #F3A847;
  background-color: var(--secondary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid #000000;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid #F3A847;
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

li {
  list-style: none;
}

nav a:hover {
  text-decoration: none;
}

nav  li {
  cursor: pointer;
}

textarea {
	width: 600px;
	height: 120px;
	border: 3px solid #cccccc;
	padding: 5px;
}

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: calc(1rem * 0.5);
  margin-right: var(--spacing-one);
  margin-left: calc(1rem * 0.5);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: calc(1rem);
  margin-right: var(--spacing-two);
  margin-left: calc(1rem);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: calc(1rem * 0.5);
  margin-top: var(--spacing-one);
  margin-bottom: calc(1rem * 0.5);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: calc(1rem);
  margin-top: var(--spacing-two);
  margin-bottom: calc(1rem);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: calc(2.5rem);
  margin-top: var(--spacing-five);
  margin-bottom: calc(2.5rem);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: calc(1rem * 0.5);
  padding-right: var(--spacing-one);
  padding-left: calc(1rem * 0.5);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: calc(1rem);
  padding-right: var(--spacing-two);
  padding-left: calc(1rem);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: calc(1rem * 0.5);
  padding-top: var(--spacing-one);
  padding-bottom: calc(1rem * 0.5);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: calc(1rem);
  padding-top: var(--spacing-two);
  padding-bottom: calc(1rem);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: #111111;
	background: var(--darker);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}

button {
	background: #8c54ff;
	background: var(--primary);
	border: 0;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 30px;
	padding: 10px 15px;	
}

a {
	border-radius: 5px;
	color: #8c54ff;
	color: var(--primary);
	font-weight: 600;
	padding: 2px 4px;
	margin-left: -2px;
	margin-right: -2px;
	
}

.navActive {
  color: #F3A847;
  color: var(--secondary);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

